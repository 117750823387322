import React from 'react';
import classnames from 'classnames';

import DBProgramsByRegion from '../images/charts/chart_database-programs-by-region_english.png';
import DBProgramsByType from '../images/charts/chart_database-programs-by-type_english.png';
import INNPartnerByRegion from '../images/charts/chart_deep-dive-programs-by-region_english.png';
import INNPartnerByType from '../images/charts/chart_deep-dive-programs-by-type_english.png';

const BlueprintTemplate = () => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <article className={'module-featured'}>
          <h1>The Incarceration Nations Network’s global platform:</h1>
          <p>Notes & Reflections</p>
          <figure>
            <img
              src="https://www.berlin.de/converjon/?ts=1418132797&width=540&height=270&url=https%3A%2F%2Fwww.berlin.de%2Fjustizvollzug%2F_assets%2Fjva-heidering%2Fdie-anstalt%2Fpforte.jpg"
              alt="Placeholder"
              width="1440"
              height="710"
            />
          </figure>
        </article>
        <article className={'s16'} id={'context'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              Mass incarceration is a global calamity.
            </h2>
          </header>
          <div className={'triple'}>
            <div>
              <p>
                Some 11 million people worldwide are currently behind bars. This
                number is steadily increasing. Between 2008 and 2011, the prison
                population grew in 78 percent of all countries, and between 2000
                and 2016 it showed an increase of almost 20 percent. Between
                2000 and 2015, the total prison population in Oceania increased
                by almost 60 percent; in the Americas it increased by over 40
                percent overall (14 percent in the US; more than 80 percent in
                Central American countries; 145 percent in South American
                countries). The number of life‐sentenced prisoners around the
                world has nearly doubled since 2000.
              </p>
            </div>
            <div>
              <p>
                More than 3.2 of the 11 million people behind bars have not been
                convicted of anything—they are legally innocent people awaiting
                trial. One in five of the 11 million is incarcerated for
                drug-related offenses, 83 percent for simple drug possession. A
                report for the{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://justice.sdg16.plus/report"
                >
                  Task Force on Justice
                </a>{' '}
                calculated that 4.4 billion people are excluded from the
                opportunity the law provides and 244 million people experience
                extreme conditions of injustice.
              </p>
            </div>

            <div>
              <p>
                Despite being known as mass incarceration, it does not impact
                the masses on equal terms. “Othered” communities—whether
                African-Americans and Latinos in the US; Indigenous people in
                Canada, Australia and New Zealand; Roma people throughout
                Europe; poor people all over the Global South—are targeted by
                and thus caught up in criminal justice systems at dramatically
                disproportionate levels, due to a matrix of historical and
                current realities grounded in capitalist norms.
              </p>
            </div>
            <div>
              <figure>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  src="https://static1.squarespace.com/static/57cd909c197aea06e48be99b/t/5ba24f944d7a9c88ca636974/1537363932300/app_at_work.JPG"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </figure>
            </div>
            <div>
              <p>
                This is not only a human rights crisis. It is also a profound
                worldwide threat to peace and community safety, because
                prisons—an outmoded, un-innovative method of generating public
                safety and reducing crime—do not build safer communities or
                advance peace and justice. Extensive data supports this. A{' '}
                <a
                  style={{ color: 'black' }}
                  href="https://penphilanthropy.org/files/Focus_Areas/Criminal_Justice_Reform/The_impacts_of_incarceration_on_crime_10.pdf"
                >
                  2017 Open Philanthropy Project report
                </a>{' '}
                reviewed 35 international studies and concluded that tougher
                sentences “hardly deter crime” and prison time “tends to
                increase [a person’s] criminality after release”—in other
                words, prisons produce crime instead of stop it. A{' '}
                <a
                  href="https://Nature.com/articles/s41562-019-0604-8.epdf"
                  style={{ color: 'black' }}
                >
                  {' '}
                  2019 University of California at Berkeley study
                </a>{' '}
                found that prison sentences don't deter people from engaging in
                violent crime, and keeping them behind bars has barely any
                effect on reducing violent crime overall.
              </p>
              <p>
                The Incarceration Nations Network is born from this: a global
                network and think tank that supports, instigates and popularizes
                innovative prison and justice reimagining efforts around the
                world.{' '}
              </p>
            </div>
            <div>
              <figure className={'m35'}>
                <img
                  src="https://static.wixstatic.com/media/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_816c8efd1eec47eab0fed67369ea700f~mv2_d_4608_3456_s_4_2.webp"
                  alt="Placeholder"
                  width="400"
                  height="175"
                />
              </figure>
              <p>
                This INN multimedia platform—to our knowledge, the first of its
                kind—is also born from this global crisis, and it is driven by a
                passion for solutions, both big picture and small. Showcasing
                innovative prison-reimagining and reform efforts around the
                world, it consists of a broad-reaching Database collating nearly
                800 global programs, and a INN Partner exploration of 82
                particularly promising programs with a range of activities and
                focuses, which we have grouped into categories.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'premises'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>PREMISES OF THE INN PLATFORM</h2>
            <p style={{ color: '#7D7D7D' }}>
              The INN platform adopts three a priori premises about justice
            </p>
          </header>
          <div className={classnames('double', 'a')}>
            <div className={'m0'}>
              <ul>
                <li>
                  <p>
                    Non prison-based models of justice grounded in restorative
                    justice and other diversionary approaches are more humane,
                    effective ways of building safe and just communities than
                    prisons and detention. These ought be not “alternatives to
                    incarceration” but the main event in justice, so to speak.
                    And this should reframe our fundamental questions about
                    justice. Instead of simply asking, “what prison
                    rehabilitation programs are effective?” we need to ask
                    questions that move us past prisons: “What creates safe
                    communities? What are the needs of those harmed and how can
                    we address them?”
                  </p>
                </li>
                <li>
                  <p>
                    While radical systemic change and innovative big-picture
                    imagining are most needed to move the global needle toward
                    justice, there is still a place for reform work and
                    incremental efforts—because there are millions of lives at
                    stake in the here and now. Reducing prison populations and
                    improving prison conditions are vital in the name of human
                    rights and community safety. It is thus possible, even if
                    paradoxical, to be engaged in life-saving reform work while
                    still working toward various modes of prison abolition that
                    obviate such reforms.
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <p>
                    When it comes to safe communities, nothing trumps
                    prevention; this translates to a society grounded in
                    equality, equity, economic opportunities and robust social
                    services. As the Justice Reinvestment movement knows, true
                    justice work cannot be done in a vacuum; it is holistic and
                    wide reaching, transforming all facets of a society in the
                    name of peace.
                  </p>
                </li>
              </ul>
              <figure>
                <img
                  src="https://www.score.gov.sg/images/default-source/default-album/chef-working-in-kitchen.jpg"
                  alt="Placeholder"
                  width="785"
                  height="280"
                />
              </figure>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'goals'}>
          <figure className={'background'}>
            <img
              src="https://mediaprocessor.websimages.com/www.corkalliancecentre.com/patricks%20hill%20view.jpg"
              alt="Placeholder"
              width="1440"
              height="800"
            />
          </figure>
          <header className={'text-center'}>
            <h2>GOALS OF THE INN PLATFORM</h2>
            <p>INN has multiple goals for the multimedia platform</p>
          </header>
          <div className={'triple'}>
            <div>
              <ul>
                <li>
                  Create a blueprint for and roadmap to promising solutions to
                  the global prison crisis.
                </li>
                <li>
                  Demonstrate that conversations about prison reform and
                  reimagining can happen globally and across borders, while
                  acknowledging the limitations of sweeping generalizations from
                  country to country.
                </li>
                <li>
                  Build border-crossing coalition and bolster organizations
                  doing vital justice work, particularly organizations doing
                  this work and serving prison populations in hostile, punitive
                  public environments.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Construct a broad-reaching database so that global
                  organizations doing prison-reform work and
                  academics/researchers/innovators around the world can be,
                  literally, on the same page.
                </li>
                <li>
                  Generate a global network of change-makers that allows for the
                  replicability of strong programs around the world.
                </li>
                <li>
                  Provoke border-crossing conversations about common struggles
                  and successes.
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  Combine data with imagination to attract a new set of eyes and
                  ears to the worldwide crisis of mass incarceration, notably
                  vital players in this work: new funders, the general voting
                  public and the media.
                </li>
                <li>
                  Provide a practical resource for organizations in countries
                  with foreign-born prisoners who can benefit from connections
                  to overseas organizations.
                </li>
              </ul>
            </div>
          </div>
        </article>
        <article className={'module-wide'} id={'audience'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>AUDIENCE FOR THE INN PLATFORM</h2>
            <p style={{ color: '#7D7D7D' }}>
              INN's audience for the global platform is broad reaching
            </p>
          </header>
          <div className={'module-aside'}>
            <figure>
              <img
                style={{ maxHeight: '330px', objectFit: 'cover' }}
                src="https://www.crcnsw.org.au/wp-content/uploads/2016/10/highlight2.jpg"
                alt="Placeholder"
                width="865"
                height="330"
              />
            </figure>
            <div>
              <p>
                Government agencies and ministries; NGOs working in the justice
                space; media, academics and students in justice-related fields;
                potential funders from the corporate sector; philanthropies and
                other funders; activists and concerned citizens; incarcerated
                and/or formerly incarcerated individuals “living in the closet,”
                where they battle stigma and social exclusion; families and
                loved ones of the incarcerated community.
              </p>
            </div>
          </div>
        </article>
        <article className={'mn'} id={'methodology'}>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              METHODOLOGY FOR DATA COLLECTION/ORGANIZATION OF THE INN PLATFORM
            </h2>
          </header>
          <div className={'double a module-spaces tablet-compact'}>
            <div>
              <p>
                For the Database, containing nearly 800 programs worldwide, INN
                consulted with its team of Global Justice Consultants, as well
                as academic advisors and team members from various foundations.
                INN also engaged in systematic web searching using key words in
                conjunction with country names (“prison” “prison reform”
                “restorative justice,” “diversion,” “alternative to
                incarceration” "court/ offender diversion"). Included were any
                programs with a functioning website and contact information. To
                INN’s knowledge the Database is the only global mapping of this
                extent and kind, but it does not claim to be absolute. Web
                searching was conducted primarily in English and Spanish. 
              </p>
              <p>
                In selecting programs to feature in the INN Partner section of
                the platform, INN consulted with its Global Justice Consultants
                and requested recommendations of especially strong programs;
                on-the-ground contacts from academic and NGO contexts were
                consulted, as well. Priority was given to programs in which
                directly impacted people play a central role, because INN
                believes, in the words of JustLeadership USA, that “those
                closest to the problem are closest to the solution.”
              </p>
              <p>
                In categorizing programs in the Database, INN used publically
                available information. Organizations in the INN Partner platform
                categorized their programs themselves. Often one
                organization/institution runs multiple types of programs, each
                of which is listed as a separate individual program. Many
                organizations engage in a combination of direct service and
                advocacy, and often don't see them as separate “programs”; we
                categorized this synergetic work under separate program types.
              </p>
            </div>
            <div>
              <p>
                Participating organizations in the INN Partner platform were
                sent one of four 50-question questionnaires (
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    here
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/2bs7ebzwYpqUTW0e38aVz4/8b82c979473cd3023d7dc7edfe07a02b/INN_Alternatives_to_Incarceration_Questionaire_.pdf"
                  >
                    here
                  </a>
                  ,{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/16pO6mhH06ep3rvEKWtAF4/a498468563c0348dae7092be6a599af5/INN_DirectServices_Questionaire_.pdf"
                  >
                    here
                  </a>{' '}
                  &{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/5MOyuKg9Phsrh1yLhcub56/def96255a13b7ba334ff22bfa22bf77f/INN_DirectServices_Innovative_Alternatives_to_Traditional_Prisons_Questionaire_.pdf"
                  >
                    here
                  </a>
                </span>
                ) about their work, their methods, their theories of change and
                more; these questionnaires included both open-ended and
                closed-answer choices in an effort to collect both quantitative
                and qualitative data about each program. 39 organizations were
                also sent{' '}
                <span className="strong">
                  <a
                    style={{ color: 'black' }}
                    href="https://assets.ctfassets.net/fhwlk5jsnns3/1HPZ9vmVLMG2nZrwsZu6X4/87c80904023e859a826f35a97e07cbeb/INN_GlobalAssessment_ClientSurvey.docx"
                  >
                    client surveys
                  </a>
                </span>{' '}
                to distribute to five program participants; these were for use
                in assembling qualitative data about the programs.
              </p>
              <figure className={'mn'}>
                <img
                  src="https://www.genepi.fr/wp-content/uploads/2018/03/Re%C2%A6%C3%BCflexion-et-formation.jpg"
                  alt="Placeholder"
                  width="620"
                  height="555"
                />
              </figure>
              <p>
                52 programs in the INN Partner platform were visited by INN
                Executive Director Dr. Baz Dreisinger, including at least one
                from every geographical region. Interviews were held with key
                individuals representing the organization and with clients.
              </p>
              <p>
                Several programs were asked to participate in the INN Partner
                platform but were unable to, for a variety of reasons. Of these,
                INN felt that some were too vital to leave out, so pages were
                created for them to showcase their mission, approach and media
                coverage while excluding the more in-depth data, which could not
                be gathered at this time.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-wide'}>
          <div className={classnames('double', 'a', 'tablet-compact')}>
            <div>
              <p className={classnames('strong', 'm40')}>
                In selecting programs for the INN Partner platform, an effort
                was also made to balance several factors so as to allow for:
              </p>
              <hr />
              <ul>
                <li>
                  Inclusion of programs from a wide range of geographical
                  regions
                </li>
                <li>
                  A range of broad-reaching national and smaller-scale local
                  programs
                </li>
                <li>
                  A relevant mix of big-picture, systems-oriented programs as
                  well as direct-service, individual-focused programs “cleaning
                  up the mess” and saving lives in the here and now
                </li>
                <li>
                  Inclusion of both established, long-running programs and
                  organizations with smaller, newer programs who may not have
                  equivalent tested-and-proven results (yet) but whose model is
                  promising and innovative and thus would benefit from a
                  presence on a global platform.
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTE:</span> Missing from this list is
                prevention—including social service opportunities, community
                interventions (especially youth-oriented ones) and economic
                development—but that is beyond the scope of INN’s platform at
                the present time.{' '}
              </p>
              <p>
                INN also noted work focused on relevant directly impacted
                categories:
              </p>
              <ul>
                <li>Overrepresented groups in the criminal justice syste </li>
                <li>Women in the justice system</li>
                <li>Remand/Pretrial populations</li>
                <li>Juveniles/Youth</li>
                <li>Families of the incarcerated </li>
                <li>LGBTQ prison populations </li>
              </ul>
            </div>
            <div>
              <p className={classnames('strong', 'm40')}>
                Identifying the organizing categories of the platform was step
                one in outlining common ground among programs and creating a
                kind of solutions blueprint or roadmap. These categories are:
              </p>
              <hr />
              <ul>
                <li>Alternatives to Incarceration</li>
                <li>Policy/Advocacy work</li>
                <li>
                  Direct Services for those directly impacted by the prison
                  system (Incarcerated/formerly incarcerated people and their
                  families)
                </li>
              </ul>
              <hr />
              <p>
                <span className="strong">NOTE: </span>Defining “success” when it
                comes to prison reform efforts remains a complex, nuanced
                matter. Recidivism is commonly used as a measure, but as
                researchers and practitioners continuously note, such numbers
                are often deceptive and simplistic. (Brazil and El Salvador, for
                instance, have overall lower rates of recidivism than Chile, but
                this does not necessarily speak to the success of the latter
                countries’ prison systems—it can simply be attributed to the
                short sentences doled out in Chile, causing people to move in
                and out of the system at higher rates). Given this reality, INN
                explicitly asked organizations about their ideas about success
                (i.e. “what would render your work unnecessary?” “What is your
                program’s vision for long-term, transformative change?”) and as
                a whole looked to gather both quantitative and qualitative data
                about the programs in showcasing their work. More on this and on
                the issue of evidence-based practice as a whole can be found in
                the Big-Picture Reflections section.
              </p>
              <p>
                <span className="strong">NOTE: </span>In the name of
                accessibility—a guiding principle of all INN work—this platform
                utilizes popular terminology that INN admittedly finds
                problematic. As mentioned above, “alternative to incarceration”
                erroneously puts prison at the heart of a justice system. Terms
                like “rehabilitation” and “reentry”/“reinsertion” imply that
                people in prison have been habilitated/inserted into society in
                the first place, when in fact we know the opposite to be true:
                Globally speaking, people in prison come from the most
                marginalized, deprived communities and thus any opportunity
                afforded to them in prison is likely a first chance at
                opportunity, not a second chance.{' '}
              </p>
              <p>
                <span className="strong">NOTE: </span>The INN Partner section of
                this platform is not an evaluation of programs using a universal
                measurement tool; programs were selected because they already
                come heralded as valuable and worth holding up as potent models.
                Instead, the section is a showcasing of common struggles and
                strengths shared by programs across borders and an underscoring
                of strong work in a variety of contexts. More discussion of this
                can be found in the Big-Picture Reflections section.
              </p>
            </div>
          </div>
        </article>
        <article className={'module-featured'} id={'common'}>
          <h1>COMMON STRUGGLES & STRENGTHS</h1>

          <figure>
            <img
              src="https://i.vimeocdn.com/video/693991407.webp?mw=1440&mh=710&q=70"
              alt="Placeholder"
              width="1440"
              height="710"
            />{' '}
            <i className={'icon-pluses'}></i>
          </figure>
        </article>
        {/* <div>
          <PageMenu menuItems={BLUEPRINT_SUB_MENU} />
        </div> */}
        <article>
          <header className={'text-center'}>
            <h2 style={{ color: 'black' }}>
              THE MASTER DATABASE (783 PROGRAMS)
            </h2>
          </header>
          <div>
            <h3 style={{ textAlign: 'center' }}>Figure 1</h3>
            <p style={{ textAlign: 'center' }}>
              Master Database of programs by region and type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            {/* Database programs by region */}
            <img
              src={DBProgramsByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            {/* Database programs by type */}
            <img
              src={DBProgramsByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Table 1</h3>
            <p style={{ textAlign: 'center' }}>
              Database programs by region and type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://images.ctfassets.net/fhwlk5jsnns3/6IH0a3OG2w9sMO9yR9aTPn/23a6d3c2ab0aff0b468c5b370c6ff62f/table1.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Figure 2</h3>
            <p style={{ textAlign: 'center' }}>
              INN Partner programs by region and type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            {/* INN Partner Programs by Region */}
            <img
              src={INNPartnerByRegion}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            {/* INN Partner Programs by Type */}
            <img
              src={INNPartnerByType}
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>

          <div>
            <h3 style={{ textAlign: 'center' }}>Table 2</h3>
            <p style={{ textAlign: 'center' }}>
              INN Partner programs by region and type
            </p>
          </div>

          <figure style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="https://images.ctfassets.net/fhwlk5jsnns3/oVo7xJsAgrsHPjYGiSMlf/a2d18e741cacbfc3df628de2fce98950/table2.png"
              alt="Placeholder"
              width="650"
              height="650"
            />
          </figure>
        </article>
        <article className={classnames('module-wide', 'overlay-b')}>
          <div id={'alternatives'}>
            <header className={'text-center'}>
              <h2 style={{ color: 'black' }}>Alternatives to incarceration</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Globally, there is a dearth of alternative to incarceration
                  programs (ATIs). INN located far more direct services and
                  advocacy/policy programs than ATIs; of all the programs in the
                  Database at data-assessment time, only 14% (111 programs) were
                  ATIs (as compared to 60% direct services and 26%
                  advocacy/policy). Of those in the INN Partner, 63% of ATIs
                  named both their immediate and short-term goal as “piloting RJ
                  practices,” suggesting a strong need for the development and
                  support of new programs in this arena.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Nearly one-third (31.1%) of INN Partner ATI programs named the
                  main problem they aim to address as
                  “Discrimination/overrepresentation of certain groups (based on
                  indigenous culture or race) in prisons.”
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>

                <p>
                  Where strong ATIs (especially restorative justice programs) do
                  exist, they are almost always for juveniles only. There
                  appears to be great hesitancy to afford these opportunities to
                  adults—who, after all, were once juveniles facing an injurious
                  system, too.
                </p>
                <figure>
                  <img
                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                    src="https://cdhep.org.br/wp-content/uploads/2019/04/Curso_V%C3%ADtima_Ofensor_Comunidade_1.jpg"
                    alt="Placeholder"
                    width="415"
                    height="200"
                  />
                </figure>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  ATI programs have been around for a long time: on average, 18
                  years—the longest-standing programs of all three major
                  categories examined in the INN Partner. ATI programs also have
                  wide reach, boasting the largest average number of
                  participants of the three program categories. ATIs have some
                  of the largest staff among the INN Partner programs but they
                  also rely heavily on volunteers; on average 30% of individuals
                  involved in the organizations are not paid staff. ATIs are
                  also more likely to have at least one incarcerated or formerly
                  incarcerated staff member (30% of programs).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  ATIs are significantly cheaper than direct service programs
                  (calculated either as overall program cost, or cost per
                  beneficiary). There is a wide range of annual program costs
                  (from $4000 to 15 million USD), but the most expensive
                  alternative to incarceration program is three times cheaper
                  than the most expensive direct services program.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  For half of ATIs, the long-term transformative vision for
                  change was “Alternative sentencing/Restorative justice is the
                  principal response to crimes or convictions.”
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'restorative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>Restorative justice</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTE: </span> Many ATI programs
                  involve aspects of RJ and therapeutic jurisprudence (i.e. in
                  New Zealand, for instance, the Sentencing Act of 2002 contains
                  clear restorative provisions that oblige sentencing judges to
                  take into account restorative processes). INN categorized a
                  program as an RJ program only if it explicitly labeled itself
                  as such.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  In many parts of the world, RJ is still very much an unknown
                  or vague concept to the general public—it faces a public
                  relations crisis. One US program had to stop describing its
                  work as “community conferencing” because it was mistaken for a
                  company that rents conference space; they also spoke of still
                  being an unknown option to many schools and justice systems in
                  the area, despite having been around for more than two
                  decades.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Strong facilitators who engage in what Restorative Response
                  Baltimore (USA) calls a “continuing growth model” are vital to
                  the success of any RJ intervention, as opposed to
                  broad-reaching, singular facilitator trainings that often
                  water down the process or the concepts, and train swathes of
                  people who may not be an ideal fit for the highly nuanced
                  facilitator role.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  RJ programs were selected for the INN Partner platform in part
                  because they divert people out of and/or bypass the
                  traditional criminal justice system instead of acting as an
                  addendum to it (i.e. programs working with people who are
                  already incarcerated). INN feels more enthused about these
                  programs because they are non-prison-based approaches to
                  justice.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'diversion'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>Diversion</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Many diversion programs involve referring people to external
                  programs in lieu of prison (drug/alcohol treatment, mentoring,
                  grief services etc.); to that end, 43% of ATIs identified
                  their primary key to success as “collaboration with external
                  partners.” But many organizations express that such programs
                  are under-resourced and/or lacking in various
                  respects—especially with regard to cultural sensitivities
                  toward overrepresented groups. Aboriginal Legal Services
                  (Canada) responds to this issue with a valuable approach:
                  becoming a one-stop shop, building in-house programs that are
                  by and for Aboriginal people.
                </p>
                <img
                  className={'desktop-only'}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/bxp41924s.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Personalized, holistic diversion plans are vital—there is no
                  one-size-fits-all formula. In strong programs, various
                  agencies work in synergy, with personalized knowledge of each
                  person before them and in collaboration with families, social
                  workers, education and health systems. In New Zealand, for
                  instance, the Family Group Conferences, mainstream Youth Court
                  and Rangatahi work together to generate personalized plans;
                  the Red Hook Community Justice Center (USA) calls itself
                  “person-centered” and does not categorize clients even based
                  on type of crime. In the Netherlands, Halt sees 16,000 young
                  people per year—one third of all children arrested in the
                  country—but still manage to create individualized diversion
                  plans for them, with each process taking on average three
                  months. The program is consistently evolving and innovating,
                  moving into prevention by working in schools, experimenting
                  with work on various age groups and using gaming and VR in
                  learning assignments. 75% of those it diverts do not commit
                  new offenses.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Indigenous courts in both New Zealand and Australia, as well
                  as the Red Hook Community Justice Center (USA), reveal the
                  sometimes small, subtle ways in which the legal setting can be
                  humanized and made culturally relevant to reflect what
                  founders of the Koori Court in Australia call “lore instead of
                  law”: lack of excessive formality; allowing the accused a
                  strong voice and central role in the process; emphasis on
                  community via ritual, song, touching of hands and faces, along
                  with vital presence of elders/respected persons; speaking in
                  plain English and not “legalese”; removing physical barriers
                  constructed by Western (white) law and creating a seating
                  arrangement that reflects this, as opposed to hierarchies and
                  power (i.e. the judge not sitting on an elevated platform);
                  taking time with the process instead of rushing through an
                  assembly-linestyle of justice; paying attention to design of
                  courtroom space: presence of natural light and/or welcoming
                  murals and avoidance of dark wood; using respectful language
                  (“please” and “thank you”) on signage throughout.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'mediation'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>MEDIATION</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  In countries around the world, legal systems are glutted,
                  lawyers (or lawyers for clients who cannot pay) are scarce—and
                  the best response is a bottom-up, as opposed to a top-down,
                  approach to justice: Divert people before they even enter the
                  legal sphere. Formal lawyers are often not needed to move
                  toward resolutions; legal empowerment and community
                  peacemakers—like those in the Madaripur Mediation Program in
                  Bangladesh and the Red Hook Community Justice Center’s
                  Peacemaking Program (USA), in which peacemakers are trained by
                  the Navajo Nation—are thus vital. Development of
                  government-supported community peacemaking initiatives like
                  the Red Hook Community Justice Center’s is an area ripe for
                  growth.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  In Navajo society,
                  <span style={{ fontStyle: 'italic' }}> k’e</span> is a kinship
                  system with such values as respect, kindness, cooperation,
                  friendliness and reciprocal relations. Behavior that flouts
                  the rules of k’e can leave one dubbed “acting as if he or she
                  has no relatives.” Successful mediation programs are able to
                  tap into such community and family values in order to bring
                  about peace.
                </p>
              </div>
            </div>
          </div>

          <hr />
          <div id={'advocacy'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>POLICY/ADVOCACY</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Around the world, prison reform organizations are advocating
                  for many of the same things, broadly speaking: 1. Reform to
                  policies on petty offenses and/or the draconian war on drugs;
                  2. Sentencing reform; 3. Improvement of prison conditions; 4.
                  Pretrial/bail policy. The latter (pretrial policy) is
                  especially universally relevant, named by 50% of INN Partner
                  advocacy programs as the among the most important areas of
                  policy they seek to influence.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.incarcerationnationsnetwork.com/wp-content/uploads/2016/02/8109-org.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Many organizations are using the same methods to tackle above
                  issues: 1. Engagement with policy makers; 2. Strategic
                  litigation; 3. Changing the public narrative via media
                  campaigns and/or arts; 4. Producing research and reports. When
                  it comes to program rationale, 65% of programs named “If
                  people in decision-making positions have more accurate
                  information, they will make better decisions,” while 45% named
                  “If people in decision-making positions have more empathy for
                  the human lives and stories affected by their choices, they
                  will make better decisions.”
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Some highly important issues ripe for strategic litigation cut
                  across borders: bail reform; barriers to education, employment
                  and housing for formerly incarcerated people (known in the US
                  as the “Ban the Box” movement); the issue of judicial
                  holidays, during which judges on leave or in shortage for
                  months at a time clogs the system and packs prisons. Justice,
                  INN argues, does not go on holiday—ever.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'directServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>DIRECT SERVICES</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  “Lack of existing rehabilitation programs” was identified by
                  20% of all 81 INN Partner Direct Service programs as the main
                  problem or need that their program addresses. Yet in the
                  Database 60% of the programs world-wide fall under “Direct
                  Services” (see Figure 1); of those, the largest concentration
                  of programs fall under the “Reentry” category (153 programs,
                  20% of all programs) and “Other Rehabilitative Services” (79
                  programs, 10% of all programs). Just 14% of all programs (111)
                  fall under Alternatives to Incarceration. One conclusion from
                  this: Too often the life-saving efforts to clean up the mess
                  of incarceration via direct services—especially with regard to
                  reentry—overshadow, subsume and absorb the resources of
                  systemic, big-picture efforts to undo it. And yet these
                  direct-service efforts are still not enough, as organizations
                  nonetheless see a lack. d Hook Community Justice Center’s is
                  an area ripe for growth.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  The multiple components of direct service work persistently
                  overlap; it is virtually impossible to face just one of a
                  justice-involved person’s needs without simultaneously facing
                  many more needs. For this reason having a multidisciplinary or
                  holistic team is critical. AJEM (Lebanon), for instance,
                  houses lawyers, social workers and psychologists in order to
                  take what they describe as a “multidimensional approach
                  through prevention, rehabilitation and social reinsertion.”
                  The Red Hook Community Justice Center (USA) is both
                  multidimensional and multi-jurisdictional, collaborating not
                  only with the prosecutor’s office and the courts but also with
                  housing, educational, health care and legal offices, both
                  government and NGO.
                </p>
              </div>
            </div>
            <hr />
            <div>
              <header className={classnames('text-center')}>
                <h2 style={{ color: 'black' }}>LEGAL/BAIL ASSISTANCE</h2>
              </header>
              <div className={classnames('double', 'a', 'hr')} id={'legal'}>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    Common struggle:
                  </h3>
                  <p>
                    In many countries, especially in the Global South, the law
                    acts an invisible neo-colonial force, governing peoples’
                    lives without their even being aware of how it operates.
                    Many worldwide legal systems with colonial roots have been
                    superimposed on a community context for which they are a
                    dramatically ill fit; the perfect synecdoche for this is the
                    image of an African judge in a country like Ghana wearing a
                    formal gown and old British white wig in a sweltering
                    courtroom worlds away from England. As MyJustice Myanmar
                    reminds us, “law” means “lying” in the Karen language.
                    Organizations like CHREAA (Malawi), MyJustice (Myanmar) and
                    Advocaid (Sierra Leone) are working to make the law
                    accessible and understandable by utilizing community theater
                    and art to reach the masses, especially in rural areas.
                  </p>
                  <img
                    style={{
                      minHeight: '295px',
                      minWidth: '100%',
                      objectFit: 'cover',
                    }}
                    src="https://advocaidsl.org/wp-content/uploads/2018/12/IMG_0774.jpg"
                    alt="Placeholder"
                    width="360"
                    height="295"
                  />
                </div>
                <div>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    Common strength:
                  </h3>
                  <p>
                    Globally, organizations are employing several common
                    approaches to assist and reduce prison populations,
                    especially remand populations. Such approaches include: 1.
                    Providing legal assistance as early on in the process as
                    possible (in police stations in India via CHRI, for
                    instance) along with various kinds of additional wraparound
                    services (Advocaid, Sierra Leone; Brooklyn Defender
                    Services, USA); 2. Developing culturally sensitive risk
                    assessments to clear people out of pretrial detention (JJAI,
                    Mexico); 3. Using mobile courts and behind-bars legal
                    training to clear people out of detention (Justice for All
                    programme, Ghana); 4. Generating bail funds and advocating
                    for bail reform (The Bail Project, USA); 5. Developing and
                    accrediting incarcerated lawyers and paralegals to clear
                    backlogs of cases (African Prisons Project, Kenya & Uganda);
                    6. Providing legal aid as well as legal education/
                    empowerment skills (CHREAA, Malawi).
                  </p>
                  <h3 style={{ color: 'black', fontSize: '18px' }}>
                    Common strength:
                  </h3>
                  <p>
                    Organizations taking a holistic approach to legal
                    assistance, such as Brooklyn Defender Services (USA) and
                    Advocaid (Sierra Leone), offer multiple kinds of social
                    services to their clients, recognizing that those caught up
                    in the criminal justice system often have needs that extend
                    beyond legal assistance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div id={'reentry'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>REENTRY</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  “Reentry/Reintegration” is the largest program category on
                  both the INN Partner and the Database lists, suggesting what a
                  tremendous crisis the issue is globally and the amount of
                  resources being spent to address it. 34% of Inn Partner Direct
                  Service programs of all varieties named their objective as “To
                  improve conditions, resources, and prospects for
                  re-integration after release” (with 27% naming it as, “To
                  improve incarcerated people’s well-being and skills during
                  imprisonment”).
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Profound barriers to jobs, housing and education for returning
                  citizens are global realities. Yet efforts to remove such
                  barriers—both legal (via strategic litigation and other
                  advocacy work) and cultural (via changing the narrative about
                  formerly incarcerated people and community acceptance)—are
                  scarce almost everywhere but the US, where they are
                  collectively know as the “Ban the Box” movement (referring to
                  the “do you have a criminal-record box?” which applicants must
                  tick on applications). Even in countries like the Netherlands,
                  where extensive barriers do not exist, returning citizens
                  operate under the misconception that they do and are thus
                  loathe to avail themselves of opportunities. Informal
                  barriers, like stigma and shame, are prevailing and difficult
                  to quantify.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Except for Singapore, governments lack robust reentry units,
                  both local and national, and reentry job databases (akin to
                  Singapore’s, which has 5520 employers registered in it). This
                  is a great area of need and potential growth.
                </p>
                <img
                  style={{ minHeight: '625px', objectFit: 'cover' }}
                  className={'desktop-only'}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="400"
                  height="625"
                />
                <img
                  className={'desktop-hide'}
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://www.score.gov.sg/images/default-source/module/home-content/63840343-ee9f-4340-9b5d-c3215518b279.jpg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Two things are particularly fundamental to successful reentry
                  programs: a continuum, which begins while someone is
                  incarcerated and continues when that person is released
                  (Singapore calls this “thoroughcare”; its programs are
                  committed to it); and a holistic approach to the universal
                  challenges of reentry: sourcing housing, getting jobs,
                  securing emotional and posttraumatic support from people.
                  These factors distinguish the “Reentry” category from the
                  other “Direct Services” behind bars categories, which are
                  focused on work development behind bars only. INN feels
                  strongly that all work that begins inside prisons must
                  continue outside prisons, working with people when they are
                  released.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  The most critical ingredient in any reentry program is:
                  people. No app or algorithm can substitute for human beings
                  working closely to support people coming home from prison.
                  SCORE (Singapore) has a vigorous mobile job coaching team,
                  visiting and supporting its clients as they are adjusting to
                  life in the workforce (and likely contributing to its
                  returning citizen 81% job retention rate). Community
                  Restorative Centre NSW (Australia) uses an “intensive case
                  management model,” which involves, as their staff put it, “not
                  being so clinical” in consistently engaging with returning
                  citizens, and checking in with them via on-the-job cell phones
                  regularly. Post-prison housing run by Exodus (Netherlands)
                  have live-in mentors who are available around the clock for
                  friendship and support. “Dedication and passion of staff” was
                  identified by 28.4% of INN Partner programs as the most
                  popular key ingredient of program success; in INN’s
                  discussions with clients coming home from prison, the need for
                  personalized support was underscored again and again.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  For many people, reintegration is a long process with many ups
                  and downs. This means that support must extend beyond several
                  months or even one year. Exodus (Netherlands) speaks of a kind
                  of after-aftercare program for those who have moved out of its
                  housing yet still want to check in with mentors and avail
                  themselves of support and opportunities.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Housing is a universal challenge for people coming home from
                  prison, so it is critical to be innovative in generating
                  housing opportunities, especially in cities with glutted,
                  expensive housing markets. Pension Skejby (Denmark) runs a
                  space that is home to both students and returning citizens,
                  who share common areas and build community together. The
                  Homecoming Project (USA) pairs people who have space in their
                  homes with people coming home from prison.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'education'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>EDUCATION BEHIND BARS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Robust education programs behind bars are vital; education
                  levels and community safety are always correlated—something
                  both common sense and research supports. Yet such programs are
                  sorely lacking even in places where they are relatively
                  widespread, like Europe. A{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://antoniocasella.eu/nume/Hawley_UE_education_may13.pdf"
                  >
                    {' '}
                    2013 survey of prison education and training in Europe
                  </a>{' '}
                  found that in the majority of European Union states,
                  participation in prison education and training was lower than
                  25%. Barriers to participation included previous negative
                  experiences of education; the fact that there were greater
                  incentives to take part in prison work than in education
                  programs; and a limited number of spaces in these programs.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  All manner of education levels behind bars is vital,
                  especially with regard to literacy and numeracy. But higher
                  education programs should not be overlooked. Not only do they
                  raise the bar in terms of the general public’s ideas about
                  what people in prison are capable of, they are an investment
                  in future leadership and, again by setting the bar high, a
                  pathway to genuine opportunity—as opposed to simply getting
                  by. Low-level job creation or wage employment cannot address
                  systemic inequality but investment in quality education,
                  vigorous opportunity and visionary leadership can be an avenue
                  for moving toward generative change.
                </p>
                <img
                  style={{
                    minHeight: '295px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://images.squarespace-cdn.com/content/57cd909c197aea06e48be99b/1537440132261-JEW860FOZYJPQ5GB8NNL/IMG_2054.JPG?format=750w&content-type=image%2Fjpeg"
                  alt="Placeholder"
                  width="360"
                  height="295"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  On client surveys from three university-level education behind
                  bars programs in three different countries on three different
                  continents (Kenya, the US and Italy), incarcerated students
                  praised their programs in similar terms. They emphasized that
                  higher education empowered them, made them see the future much
                  more positively and believe in their own worth (“Continuing
                  with my education has affirmed my value, and my belief that
                  there is life after incarceration,” said an NJ-Step (USA)
                  student; “Trust in myself, possibility to have a future,
                  seeing my life in colors instead of black and white” was how
                  an Articolo3 (Italy) student described the value of university
                  education while incarcerated; an African Prisons Project
                  (Uganda, Kenya) student spoke of his “change of attitude
                  towards life and perceptions too, empowerment through
                  acquiring knowledge…respect for me and to others as well as my
                  dignity enhanced”; another APP student noted that “the program
                  has proven indeed that prisoners have solutions to their own
                  problems, all they need is support”). Both APP (Uganda, Kenya)
                  and NJ Step (USA) students stated that education programs
                  bringing more stability to the prison as a whole, as well.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  INN believes that universities have a vital role to play in
                  building safer communities, and innovative forms of
                  prison-university partnerships are ripe with possibilities.
                  Universities, after all, contain a workforce in
                  training—student teachers, social workers and more—poised to
                  benefit from exposure to the incarcerated population, who in
                  turn benefit from networking with young people and future
                  leaders building their careers. Generally speaking,
                  universities are also fairly stable entities without explicit
                  political ties—something rare in countries facing political
                  and economic instability; they are thus vastly beneficial when
                  it comes to building and maintaining longstanding programs in
                  prisons.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'jobDevelopment'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>JOB DEVELOPMENT BEHIND BARS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  A{' '}
                  <a
                    style={{ color: 'black' }}
                    href="https://prisonpolicy.org/reports/outofwork.html"
                  >
                    US study
                  </a>{' '}
                  showed that in 2018, formerly incarcerated people were
                  unemployed at a rate of over 27 per cent—higher than the total
                  US unemployment rate during any historical period and nearly
                  five times higher than the unemployment rate for the general
                  population. Although similar studies do not exist globally,
                  lack of jobs for people returning from prison is consistently
                  noted as a crisis point by INN partners.
                </p>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://static.wixstatic.com/media/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.jpg/v1/fill/w_899,h_300,al_c,q_80,usm_0.66_1.00_0.01/b8d0a9_8aae039800624e0b865453feb142b110~mv2_d_4128_2322_s_2.webp"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Cooperative models (i.e. both Coostafe and Humanitas360 in
                  Brazil) are especially exciting, as they give incarcerated
                  people a stake in and ownership over their own work, impact
                  their lives upon release and resist the prison-labor (and
                  slave-labor) paradigm that has a long, oppressive global
                  history.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Client surveys reveal that many programs (i.e. Nafisika in
                  Kenya) intend to do one thing (Nafisika: give a person job
                  skills) but ultimately end up doing several things that extend
                  well beyond the vocational skills arena: fostering trust and
                  community engagement, igniting an interest in education,
                  sharpening interpersonal relations and generating empathy.
                </p>

                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Innovative programs are intentional about training their
                  clients in high-demand jobs, such as horticulture
                  (Hlumelilisa, South Africa), coding (The Last Mile, USA) and
                  entrepreneurship (Nafisika, Kenya). They also, like strong
                  reentry programs, employ a pipeline model, assisting their
                  clients with job sourcing upon release.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'staffTraining'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>PRISON STAFF TRAINING</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  The fact that this was the second-lowest populated category on
                  the Direct Services Database list, with just 14 global
                  programs focusing on prison staff, is deeply concerning. Just
                  as a school is as strong as its teachers, a prison/justice
                  system is as strong as its staff. There is increasing interest
                  globally in prison conditions and especially architecture, but
                  without a culture shift with regard to staff—including staff
                  support, training, and recruitment of progressive-minded
                  people for the job—such changes risk being merely cosmetic.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  A culture that holds corrections officers in high esteem will
                  attract strong candidates to the profession. Norway’s training
                  school and Unlocked Graduates (UK) recognize this, the former
                  paying its officers competitively and creating an attractive
                  on-the-job climate and culture; the latter working to shift
                  the perception of the job in university recruitment contexts
                  and creating a fellowship support program for its recruits.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'rehabServices'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                OTHER REHABILITATIVE SERVICES BEHIND BARS
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <img
                  style={{
                    minHeight: '275px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="http://www.insightprisonproject.org/uploads/1/1/6/0/11602085/1345745274.jpg"
                  alt="Placeholder"
                  width="360"
                  height="275"
                />
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  Client surveys from organizations in four different countries
                  on three continents (Lebanon, El Salvador, Belgium, the UK)
                  reveal common benefits of varied rehabilitative efforts behind
                  bars, whether through theater, conflict resolution or creative
                  writing. Cited most often was the trust and community bonding
                  that such programs build: “Community, building relationship
                  with others. I've become more humanitarian; I've starting
                  helping others without expecting any return” (AJEM, Lebanon);
                  “The program allows us to think together as a group in which
                  everyone gives his or her opinion. That makes us coexist as
                  friends and authors” (Contextos, El Salvador); “Until I
                  started with the program I felt alone, didn’t have anyone to
                  talk to… Now I know that I have people I can meet and talk to.
                  I don’t feel so alone” (De Rode Antraciet, Belgium); “I have
                  watched a group of prisoners with nothing immediately in
                  common bond and become their own version of a family unit,
                  showing a care and compassion for each other that has been
                  beautiful to witness” (LEAP, UK).
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div id={'health'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>HEALTH & SAFETY BEHIND BARS</h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  In the Database, just 1% of programs fall under this category,
                  despite the alarming numbers of HIV/AIDS and TB epidemics
                  behind bars, especially throughout Africa and Southeast Asia.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  The impact of the war on drugs on worldwide prison populations
                  cannot be overstated. While legalization of marijuana and the
                  idea of treating substance abuse as a public health crisis
                  instead of a criminal justice one—including harm-reduction
                  approaches—is slowly gaining global currency, this issue needs
                  far more momentum in order to make tangible impact. This is an
                  area for growth worldwide; serious inroads on drug policy
                  could reduce prison populations by dramatic proportions.
                </p>
              </div>
              <div>
                <img
                  style={{
                    minHeight: '475px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://justdetention.org/wp-content/uploads/2015/10/joke_cropped_hi_res-1024x741.jpg"
                  alt="Placeholder"
                  width="360"
                  height="400"
                />
              </div>
            </div>
          </div>
          <hr />
          <div id={'innovative'}>
            <header className={classnames('text-center')}>
              <h2 style={{ color: 'black' }}>
                INNOVATIVE ALTERNATIVES TO TRADITIONAL PRISONS
              </h2>
            </header>
            <div className={classnames('double', 'a', 'hr')}>
              <div>
                <p>
                  <span className="strong">NOTE: </span>In the Database, the
                  highest concentration of these innovative alternatives is
                  found in Europe.
                </p>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common struggle:
                </h3>
                <p>
                  Singular “model prison” facilities or wings—a country’s one or
                  two progressive exceptions to its otherwise unprogressive
                  rule—are valuable as bold indicators of what could be and
                  should be. But they are dangerous insofar as they remain an
                  excuse for widespread change, propping up the system and thus
                  becoming a hindrance to replication and systemic change. The
                  case of Amsterdam West’s Small Scale Detention Center,
                  initially an experimental open-detention model for youth
                  facing charges, is a model for how, as its founder put it, “a
                  tiny center impacted a whole country”: The facility is as of
                  2019 slated to be replicated all over the country, leading to
                  multiple youth detention centers being shut down and radical
                  systemic change.
                </p>
              </div>
              <div>
                <h3 style={{ color: 'black', fontSize: '18px' }}>
                  Common strength:
                </h3>
                <p>
                  In response to the question “What differentiates your
                  facility/facilities from traditional prisons?” three of five
                  programs named “Principles or philosophy of punishment”; two
                  of five also named “Cooperation with local communities and
                  individuals” and “Greater levels of freedom.”
                </p>
                <img
                  style={{
                    minHeight: '375px',
                    minWidth: '100%',
                    objectFit: 'cover',
                  }}
                  src="https://pulitzercenter.org/sites/default/files/styles/node_images_768x510/public/11-17-14/unusual-prison-11.jpg?itok=BrnW17ps"
                  alt="Placeholder"
                  width="360"
                  height="375"
                />
              </div>
            </div>
          </div>
        </article>
        <div id={'bigPicture'}>
          <article className={classnames('module-featured', 'box')}>
            <h2>Big-picture takeaways from the platform</h2>
            <p>
              <span
                className={classnames('strong', 'text-uppercase')}
                style={{ fontStyle: 'italic' }}
              >
                Kua tawhiti ke to haerenga mai, kia kore e haere tonu; He tino
                nui rawa o mahi, kia kore e mahi nui tonu. <br /> <br /> You
                have come too far not to go further; You have done too much, not
                to do more.
              </span>{' '}
              <br /> -Sir James Henare (Ngati Hine, 1989)
            </p>
            <figure className={'overlay-d'}>
              <img
                src="https://placehold.it/1440x710"
                alt="Placeholder"
                width="1440"
                height="710"
              />
            </figure>
          </article>
          <article className={'mn'}>
            <div className={classnames('double', 'a', 'tablet-compact')}>
              <div className={'m0'}>
                <ul>
                  <p>
                    <span className="strong">NOTE: </span>Big-picture
                    reflections on the platform and the state of global prison
                    work generally are inspired by data gathered from
                    questionnaires as well as Dr. Dreisinger’s site visits and
                    interviews both formal and informal with organizations and
                    clients. Comparative tables and graphs of select
                    questionnaire responses garnered from the INN Partner
                    programs can be found{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      here
                    </a>
                    ; a relevant bibliography can be found{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/4qE4rtxCNWUOUdpGizTuoG/a029e92dc23a6c5ad28ba03ed67bc796/GlobalAssessment_Bibilio.docx"
                    >
                      here
                    </a>
                    .
                  </p>
                  <li>
                    <span className="strong">THINKING OUTSIDE THE BOX: </span>
                    It is not only possible to be global and local at same
                    time—it is hugely beneficial to be so. When one gets outside
                    one’s own system to look at problems and solutions, the mind
                    begins to think radically about what’s possible, over and
                    above what is. Too often even the most progressive justice
                    workers are strangled by what is instead of being inspired
                    by what could be and liberated by what should be. The
                    historical trajectory of global prison practice thus far has
                    involved the US exportation of its prison model—a historical
                    experiment—to the world, first in the 19th century via
                    colonialism and then in the 20th century via globalization.
                    This can be undone by disrupting the flow of ideas:
                    Progressive US and non-US approaches can be shared, and the
                    learning process can and should operate in multiple
                    directions.
                  </li>
                  <li>
                    <span className="strong">
                      REPLICATION OF PROMISING PROGRAMS:{' '}
                    </span>
                    This is already happening in global spaces. Norway’s Bastoy
                    Prison has been replicated in the Czech Republic, for
                    instance, and the Madaripur Model of Mediation from
                    Bangladesh has been replicated in Malawi. But this process
                    is not an exact science and there is no such thing as
                    cut-and-paste justice; it is thus better to talk in terms of
                    inspiration, not replication. Programs must be mindful of
                    cultural specificities even as we acknowledge universalities
                    of poverty, racism and injustice at the core of prison
                    systems.{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://theguardian.com/social-enterprise-network/2012/nov/06/social-franchising-how-what-benefits"
                    >
                      The social franchising model
                    </a>{' '}
                    is relevant here.
                  </li>
                  <li>
                    <span className="strong">MORE THAN LIP SERVICE: </span>
                    Governments around the world have changed “Department of
                    Prisons” to “Department of Corrections,” making a prominent
                    show of this shift in terminology—but all too often this is
                    mere lip service. Governments still invest little in
                    “corrections” and instead land prisons at the bottom of the
                    budget barrel. Because there is a lack of public support for
                    prison-related programs and the overwhelming discourse is
                    punitive—tough-on-crime instead of smart-on-crime
                    oriented—governments face little pressure to spend more on
                    corrections and instead focus on more popular issues that
                    produce immediate results (and thus earn them re-election).
                    It is incumbent on governments to dictate and, for lack of a
                    better term, market smart justice to the public, not vice
                    versa—yet this is overwhelmingly not the global reality.
                  </li>
                  <li>
                    <span className="strong">
                      LACK OF RESOURCES AND SUSTAINABILITY:{' '}
                    </span>
                    The result of the above grim scenario is devastating to the
                    field of justice. Of the INN Partner programs, the most
                    common challenge identified was lack of funding, whether
                    overall or multiyear (69% percent identified this).
                    Operating on minimal budgets, organizations are left to rely
                    on volunteer labour, which is not sustainable. Organizations
                    also have an inability to think big picture about their work
                    because lack of funding leaves them scrambling to get by in
                    the here-and-now. This, in turn, hinders an organization’s
                    ability to be bold, innovative, and self-critical; it also
                    results in lack of solidarity with and sometimes awareness
                    of organizations doing related work even in their own
                    countries—a reality intensified by competition for minimal
                    funding pools.
                  </li>
                  <li>
                    <span className="strong">CHANGING THE NARRATIVE: </span>
                    To tackle the funding crisis, it is vital to change the
                    public narrative about prisons and the people in them,
                    moving from a discourse of revenge to one of repair. When
                    the general public becomes aware that prisons do not build
                    safer communities and that the bulk of people in prison are
                    in need of assistance, not harm and punishment—when, in
                    other words, prison becomes a popular cause and not, as is
                    the case almost everywhere in the world, a taboo subject
                    that invokes knee-jerk punitive reactions—they then demand
                    change. Politicians must then take heed and put their money
                    where their mouth is, and funders become more amenable to
                    supporting prison-related work. This shift in narrative has
                    already happened in the US. Less than a decade ago, prison
                    reform was a highly unpopular cause; today even the most
                    mainstream of celebrities makes it her public platform. The
                    role of the arts—films, art installations, books—is vital
                    here, as they are capable of changing hearts and minds (as
                    texts like the Oscar-nominated documentary{' '}
                    <span style={{ fontStyle: 'italic' }}>13th</span> and
                    bestselling book{' '}
                    <span style={{ fontStyle: 'italic' }}>
                      The New Jim Crow
                    </span>{' '}
                    did in the US). Almost all INN partners expressed that they
                    wished for the budget and bandwidth to devote more attention
                    to communications and messaging, amplifying their work and
                    shifting the public discourse. They also expressed
                    frustration with the sensationalist media coverage of crime
                    and prison-related issues, which keeps the general public in
                    a fearful, punitive mode and significantly hinders their
                    work. The force of public opinion is one reason why
                    evidence-based practice, while certainly of great value, has
                    its limitations: Even when governments have data and know
                    very well what “works,” they may not act with it in mind if
                    the voting public is dead-set against it. Worldwide, strong
                    programs become pawns in political party warfare—to the
                    detriment of community safety, peace and justice.
                  </li>
                  <li>
                    <span className="strong">
                      REWRITING COMMUNITY NARRATIVES:{' '}
                    </span>
                    Narrative change and storytelling are also critical when it
                    comes to communities believing and investing in themselves.
                    When a community assents to an identity as a crime-ridden
                    “ghetto,” it becomes comfortable in that narrative—it
                    becomes invested in it. But when it sees itself as an
                    empowered community known for peace and justice, it avidly
                    works to maintain that positive identity. The work of
                    JustReinvest (Australia) in Bourke is highly telling in this
                    regard. Via justice reinvestment work, a community known as
                    one of the most crime-ridden in Australia remodeled its
                    social structure and, by doing so, rewrote and revised its
                    own narrative; this new narrative—being the peaceful
                    community instead of the “dangerous” one—then becomes
                    self-perpetuating. The narratives we tell ourselves about
                    our identities have tremendous power over us.
                  </li>
                  <li>
                    <span className="strong">PUBLIC FUNDING: </span>
                    The goal of innovative programs should be systematization.
                    It is a government’s job, after all, to generate just
                    communities and to pay for the programs and structures
                    needed to produce that. The INN Partner numbers regarding
                    funding sources, though, reflect that such systemization is
                    happening unevenly around the world. Slightly more than half
                    (54% of the total INN Partner programs) are predominantly
                    funded by non-governmental sources, though Direct Services
                    (57%) and Alternatives to Incarceration (63%) are still
                    predominantly government funded overall{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (See details here)
                    </a>
                    . Of the predominantly government funded programs, though,
                    fewer than half come from budget allocations and the rest
                    from government grants. Government funding for
                    prison-related programs is heavily dependent on region. In
                    North America programs funding is closer to equal between
                    government and non-government sources; in Europe and Oceania
                    programs were predominantly (more than 70%) funded by
                    government; between 70-100% programs in Asia, Africa and
                    Latin America were funded by non-governmental sources{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (See details here)
                    </a>
                    . Where there is government funding, many organizations
                    expressed frustration with stringent requirements in terms
                    of numbers and deliverables, which they said hindered them
                    from doing quality work in the name of producing a specified
                    quantity of work.
                  </li>
                  <li>
                    <span className="strong">CREATIVE FUNDING SOURCES: </span>
                    Of predominantly non-government-funded programs in the INN
                    Partner platform, international donors make up the highest
                    category and private sector the lowest. Creative funding
                    sources such as impact investment and social enterprise
                    models were only indicated as part of the funding recipe by
                    two organizations. Given the increasing relevance of these
                    self-generating, systems-changing funding models, thinking
                    about them in a justice space ought to be much more
                    widespread and should be promoted via workshops and dynamic,
                    accessible convenings.
                  </li>

                  <li>
                    <span className="strong">COLLABORATION: </span>
                    43.2% of the INN Partner programs mentioned “collaboration
                    with (external) partners” as one of their key ingredients to
                    success; such partners include local politicians, the
                    judiciary, government officials and community-based
                    organizations{' '}
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      (see Figure 3)
                    </a>
                    . Yet again as per above, justice workers around the world
                    are generally operating in un-collaborative silos.
                    Governments should support convenings and roundtables that
                    bring various sectors engaged in this work together, and
                    create unifying entities akin to Singapore’s CARE Network,
                    which fosters synergies between them.
                  </li>

                  <li>
                    <span className="strong">OTHER CHALLENGES: </span>
                    Other important challenges most commonly mentioned by INN
                    Partner programs were: Lack of space or infrastructure for
                    activities (among Innovative Alternatives to Traditional
                    Prisons); Lack of government or political support (for
                    Alternatives to Incarceration); and Political instability or
                    turnover (for Policy/Advocacy programs).
                  </li>
                  <li>
                    <span className="strong">
                      SIMPLE YET IMPACTFUL INNOVATION:{' '}
                    </span>
                    Justice work certainly demands radical innovation. But at
                    the same time, reducing and supporting prison populations
                    often does not demand extreme new ideas but the commitment
                    and investment to enact simple yet impactful approaches—such
                    as mobile courts, diversion programs, job sourcing while
                    people are still incarcerated as opposed to after, intensive
                    post-release support, and more. In many countries, too,
                    government prison-reform programs are working in theory but
                    not in practice: the language and the model are there but
                    they are not enacted—mainly because of lack of resources and
                    thus lack of efficient effort.
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    <span className="strong">BOUTIQUE IS NOT BAD: </span>
                    The best justice is individualized—what JustSpeak in New
                    Zealand calls “tailor-made”—so smaller is often better, as
                    it is both more personalized and it allows for a
                    multiplicity of culturally sensitive approaches. Scaling up
                    is vital for widespread systemic change but when done
                    without the proper resources to support the people power
                    that is so vital to justice work—and when done too soon,
                    before a program is fully on its feet—it can be disastrous.
                    There is thus value in a multiplicity of tailor-made
                    approaches, especially as they allow for thinking and
                    innovative experiments. A worthy effort worth mentioning
                    here is Rescaled, a European movement pushing for
                    small-scale detention centers, which they say “enables
                    tailor-made reintegration pathways, allows for a more
                    personal approach, less bureaucracy, better dynamic security
                    and provides more opportunities for prisoners to take
                    responsibility and to interact with the community.”
                    Particularly when it comes to prisons and reintegration,
                    boutique is not only not a bad thing—in fact, smaller is
                    better because it allows for personalized work.
                  </li>
                  <li>
                    <span className="strong">
                      ESCHEWING THE OVERLY CLINICAL:{' '}
                    </span>
                    Strong organizations eschew clinical, service-provider
                    language and engagement in favor of normal human interaction
                    and strong relationship building. The small-scale reentry
                    houses of Exodus (Netherlands) are thus home not to
                    “clients” but “participants”; they involve many volunteers
                    who engage with their participants simply as friends and
                    colleagues, not service providers, and they do not provide
                    their mental health services in house because “we are not a
                    forensic clinic.” Ruud Jacobs, founder of the Small Scale
                    Facility Amsterdam West, described the method of the
                    facility as “not a method but a movement” and explained that
                    simply “being present” and “normalizing everything” goes a
                    very long way, especially for young people who have been
                    “clinic-ized” by social service providers for so long.
                    There, security officers are not “officers” but “hosts,”
                    also responsible for building relations with the neighbors.
                  </li>
                  <li>
                    <span className="strong">PROCESS IS CRITICAL: </span>
                    Bottom-up is always preferable to top-down. Organizations
                    need to build programs with communities, not for them.
                    JustReinvest (Australia) engaged with the town and
                    especially the elders of Bourke by empowering them to
                    determine where and how they wanted to see community
                    investment made; they did not come in with yet another
                    ready-made program ready to be retrofitted on a community
                    tired of such impositions. The Red Hook Community Justice
                    Center followed the mantra of “listen first” when becoming
                    New York’s second community court, letting community members
                    determine what the court should focus on, where it should be
                    located and what its processes should be. To that end, each
                    of the programs under the heading of the Center for Court
                    Innovation—of which the Red Hook Community Justice Center is
                    one—is unique, shaped by the particular needs of its locale
                    and community.
                  </li>
                  <li>
                    <span className="strong">OPENING UP: </span>
                    The more open a facility is—the more it allows for weekend
                    leave, work leave, educational release and more—the more it
                    can promote community integration, which is vital to
                    progressive justice efforts. Advocates and practitioners
                    worldwide should thus consider pushing for more porous
                    walls—allowing incarcerated people to participate in
                    programs in the community—rather than building more programs
                    behind the walls.
                  </li>
                  <li>
                    <span className="strong">GUIDING PRINCIPLES: </span>
                    Among clients of global organizations, the same words were
                    repeated in the context of describing strong programs:
                    confidence, empowerment, family, respect long-term vision of
                    transformative change, support, helpful, friendly,
                    motivating, empower, choices, responsibility, trust,
                    transform. Language about “feeling human” due to a strong
                    program was also recurrent, as was mention of community and
                    the vitality of group dynamics. The same words were also
                    repeated as definitive to their work: justice,
                    accountability, innovation, respect, dignity, community
                    (Figure 4).
                  </li>

                  <div>
                    <h3 style={{ textAlign: 'center' }}>Figure 4</h3>
                    <p style={{ textAlign: 'center' }}>
                      Adjectives describing the guiding principles of the
                      organization
                      <br />
                      <span style={{ fontStyle: 'italic' }}>
                        Please list up to five adjectives that best represent
                        the guiding principles and values underlying your
                        organization.
                      </span>
                    </p>
                  </div>
                  <figure style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src="https://images.ctfassets.net/fhwlk5jsnns3/2QjBoGQA2EcVd8dzOCpWkV/2c72e8025545f2c7ba36dbc1048eaaf7/wordMap.png"
                      alt="Placeholder"
                      width="650"
                      height="650"
                    />
                  </figure>
                  <li>
                    <span className="strong">
                      IMMEDIATE AND SYSTEMIC CHANGE:{' '}
                    </span>
                    Particularly exciting programs are systemic and
                    individualistic at one and the same time. That is, they are
                    able to offer direct services to individuals in the
                    here-and-now while still advocating and working toward
                    systemic change that can ultimately render their work
                    unnecessary. Programs do this in a variety of ways. African
                    Prisons Project (Kenya, Uganda) provide degrees and
                    certificates in law, but their work has also resulted in the
                    repealing of the mandatory death penalty in both countries,
                    thanks to the advocacy work of their students; by including
                    prison officers in their classes alongside incarcerated
                    students, they also fundamentally change the culture of
                    prison staff, potentially leading to more systemic change.
                    Juvenile Justice Advocates International and Equis (Mexico)
                    both engage primarily in policy work but also have Direct
                    Service side projects, allowing them to not only assist
                    clients but engage volunteers and expose them to prisons and
                    the people inside them—which contributes toward culture
                    shift. Genepi (France) engages college students throughout
                    the country to run workshops inside prisons, which provides
                    a service to incarcerated people but also serves to expose
                    young people to a humanized prison population and instill a
                    passion for justice in them—another form of wide-reaching
                    culture change. Interestingly, only eight of the INN Partner
                    programs stated that their long-term vision of
                    transformative change was “for prisons/jails to be
                    dismantled”; “drastically reducing prison populations” was
                    the most commonly cited (21 programs) (See details
                    <a
                      style={{ color: 'black' }}
                      href="https://assets.ctfassets.net/fhwlk5jsnns3/3gIp85pR7uHBwUW9K23TOb/735ece0f7899f5725ef86d0998c31fe2/Comparative_tables_of_questionnaire_responses_for_selected_programs.pdf"
                    >
                      here)
                    </a>
                    .
                  </li>

                  <li>
                    <span className="strong">PEOPLE POWER: </span>
                    Despite noted “recipes” for strong work, is it important to
                    note that systems and structures delineate justice—but
                    people make systems come alive. One person can thus make a
                    huge impact on an entire justice system—INN has seen this
                    from Costa Rica and the Czech Republic to Italy, the
                    Netherlands and Mexico, where innovative alternatives to
                    traditional prisons (in the first four countries) and
                    significant changes in pretrial detention (in Mexico) came
                    into being because one member of a justice agency was
                    determined to see them happen, against all odds and at any
                    cost; modern network theory suggest the chain-effect impact
                    of single persons and single actions. This “who works not
                    what works” concept also applies when we consider the
                    ingredients of strong programs. Again and again INN partners
                    emphasized the importance of strong staff, especially—as in
                    the Small Scale Facility Amsterdam West, where six of their
                    seven staff are Moroccan or Surinamese—staff who come from
                    similar cultural backgrounds/ communities as their clients.
                  </li>
                  <li>
                    <span className="strong">
                      BUILDING DIRECTLY IMPACTED LEADERS:{' '}
                    </span>
                    Investing in systemic change also means building leaders in
                    the realms of social work and activism—especially culturally
                    diverse and directly impacted leaders, who have lived
                    knowledge of problems and solutions and are thus a bank of
                    wisdom, not, as they are too often seen, a pool of problems.
                    Having those with lived experience guide programmatic work
                    is critical, yet on average less than 15% of staff in the
                    INN Partner programs were incarcerated/formerly incarcerated
                    individuals. Overall 30% of INN Partner programs reported
                    having at least one incarcerated or formerly incarcerated
                    staff member and 12% had incarcerated/formerly incarcerated
                    board members. Although many do have family of incarcerated
                    individuals on staff and/or more impacted people as
                    volunteers, INN hopes that organizations move in the
                    direction of including them as well-supported staff and
                    board members.
                    <br />
                    To facilitate the above, governments must stop creating
                    legislative barriers for, in the words of JustLeadership
                    USA, “those closest to the problem” to be “closest to the
                    solution.” Entire professions—social work in South Africa
                    and Canada, the legal field in Kenya, for instance—are
                    essentially off-limits to people with convictions, who have
                    vast potential to do a tremendous service to the fields and
                    their communities. Strategic litigation in this regard is
                    needed in many countries.
                  </li>
                  <li>
                    <span className="strong">
                      BROADENING “DIRECTLY IMPACTED”:{' '}
                    </span>
                    When we speak of those impacted by the criminal justice
                    system, we must broaden our definition: not only must we
                    consider those who have been to prison but also their
                    families, those from over-incarcerated communities who
                    managed to eschew criminal-justice involvement and the
                    neighborhoods they come from—neighborhoods that are
                    overrepresented in the criminal justice system overall.
                    Services for and attention to families of the incarcerated
                    are increasing worldwide, from across Europe to Singapore to
                    the USA, but services for overrepresented
                    neighborhoods/communities are scarce. One exception is the
                    Red Hook Community Justice Center (USA), whose robust
                    services—legal, educational and cultural—are available to
                    all members of the Red Hook, Brooklyn, community, not just
                    those coming into conflict with the law.
                  </li>

                  <li>
                    <span className="strong">TAKING ON TRAUMA: </span>
                    Justice work anywhere cannot be done without addressing the
                    elephant in the room: trauma. The prison population is
                    shrouded in it, both before prison (as a result of systemic
                    inequality and racism), during the dehumanizing years in
                    prison, and upon release, facing reintegration and
                    stigmatization. “The essence of trauma is disconnection from
                    ourselves,” says physician Gabor Mate; prison is the
                    ultimate in disconnection, in so many respects.
                    Trauma-informed practices must thus govern all
                    interventions. It should especially be a focus of
                    rehabilitation and reentry programs; vocational skills,
                    education and jobs are vital but efforts in that direction
                    can be fast sabotaged when a person’s trauma is not
                    addressed and healed.
                  </li>
                  <li>
                    <span className="strong">RADICAL INNOVATION: </span>
                    Broadly speaking, the justice space—a fundamental pillar of
                    any stable society—deserves the freedom and, especially, the
                    funding support to experiment and radically innovate. If
                    Silicon Valley can spend freely experimenting with apps and
                    technology until it hits upon what works, ought justice
                    workers not be allowed the freedom and backing to do the
                    same? We all deserve a world in which radical imagination is
                    valued and nurtured, in the name of the greater good.
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlueprintTemplate;
