import React from 'react';

import BlueprintLayout from '../components/ui/Layout/BlueprintLayout';
import BlueprintTemplate from '../templates/BlueprintTemplate';
import { BLUEPRINT_MENU } from '../utils/constants';

const Blueprint = () => {
  return (
    <BlueprintLayout menuItems={BLUEPRINT_MENU}>
      <BlueprintTemplate />
    </BlueprintLayout>
  );
};

export default Blueprint;
